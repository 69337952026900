import React, { CSSProperties, useMemo } from 'react'
import { Routes, Route, Outlet, useLocation, Location } from 'react-router-dom'
import { ModalUnstyled } from '@mui/base'
import { ViewWrapper } from './components/ViewWrapper'
import { DialogProvider } from './dialogs'
import {
  StateProvider,
  createStore,
  asyncFlow,
} from '@the-platform-company/appbuilder-react-state'
import { RobotenDnDProvider } from '@the-platform-company/react-drag-drop'
import { useConnectionSecrets } from '@the-platform-company/appbuilder-react-connector'
const ModalsView = React.lazy(
  () => import(/* webpackChunkName: "views/modals" */ './views/Modals')
)
const MasterView = React.lazy(
  () => import(/* webpackChunkName: "views/master" */ './views/Master')
)
const MasterMenusView = React.lazy(
  () => import(/* webpackChunkName: "views/menus" */ './views/MasterMenus')
)
const NotFoundView = React.lazy(
  () => import(/* webpackChunkName: "views/not-found" */ './views/NotFound')
)
const ModalsAddMobilePhoneView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/add-mobile-phone" */ './views/ModalsAddMobilePhone'
    )
)
const ModalsAddEmailView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/add-email" */ './views/ModalsAddEmail')
)
const ModalsAddEmailAndPasswordView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/add-email-and-password" */ './views/ModalsAddEmailAndPassword'
    )
)
const ModalsConfirmDeleteEmailView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/confirm-delete-email" */ './views/ModalsConfirmDeleteEmail'
    )
)
const ModalsEditProfileView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/edit-profile" */ './views/ModalsEditProfile'
    )
)
const ModalsLanguageAndRegionView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/language-and-region" */ './views/ModalsLanguageAndRegion'
    )
)
const ModalsConfirmDeleteMobilePhoneView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/confirm-delete-mobile-phone" */ './views/ModalsConfirmDeleteMobilePhone'
    )
)
const ModalsConfirmCloseAccountView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/confirm-close-account" */ './views/ModalsConfirmCloseAccount'
    )
)
const ModalsAvatarPickerView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/avatar-picker" */ './views/ModalsAvatarPicker'
    )
)
const ModalsChangePasswordView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/change-password" */ './views/ModalsChangePassword'
    )
)
const ModalsConfirmRemoveLoginView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/confirm-remove-login" */ './views/ModalsConfirmRemoveLogin'
    )
)
const ModalsAddSocialLoginView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/add-social-login" */ './views/ModalsAddSocialLogin'
    )
)
const ModalsConfirmDeleteIdentityAssignmentView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/confirm-delete-identity-assignment" */ './views/ModalsConfirmDeleteIdentityAssignment'
    )
)
const ModalsConfirmDeleteLoginLogEntryView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/confirm-delete-login-log-entry" */ './views/ModalsConfirmDeleteLoginLogEntry'
    )
)
const MasterEmailsView = React.lazy(
  () => import(/* webpackChunkName: "views/emails" */ './views/MasterEmails')
)
const MasterProfileView = React.lazy(
  () => import(/* webpackChunkName: "views/profile" */ './views/MasterProfile')
)
const MasterApiKeysView = React.lazy(
  () => import(/* webpackChunkName: "views/api-keys" */ './views/MasterApiKeys')
)
const MasterMobilePhonesView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/mobile-phones" */ './views/MasterMobilePhones'
    )
)
const MasterLoginMethodsView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/login-methods" */ './views/MasterLoginMethods'
    )
)
const MasterCloseAccountView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/close-account" */ './views/MasterCloseAccount'
    )
)
const MasterSecurityView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/security" */ './views/MasterSecurity')
)
const MasterApiKeysApiKeyCreatedView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/api-key-created" */ './views/MasterApiKeysApiKeyCreated'
    )
)
const MasterApiKeysCreateApiKeyView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/create-api-key" */ './views/MasterApiKeysCreateApiKey'
    )
)
const MasterMenusMobilePhonesMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/mobile-phones-menu" */ './views/MasterMenusMobilePhonesMenu'
    )
)
const MasterMenusEmailsMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/emails-menu" */ './views/MasterMenusEmailsMenu'
    )
)
const MasterMenusApiKeysMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/api-keys-menu" */ './views/MasterMenusApiKeysMenu'
    )
)
const MasterMenusUserMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/user-menu" */ './views/MasterMenusUserMenu'
    )
)
const MasterMenusApplicationMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/application-menu" */ './views/MasterMenusApplicationMenu'
    )
)
const AccountAuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/AccountAuthCallback" */ './components/connectors/AccountAuthCallback'
    )
)
const Tosopr004AuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/Tosopr004AuthCallback" */ './components/connectors/Tosopr004AuthCallback'
    )
)
const store = createStore()

const modalStyles: CSSProperties = {
  position: 'fixed',
  zIndex: '1300',
  inset: '0px',
}
const App: React.FC = () => {
  const location = useLocation()

  const state = (location.state as { backgroundLocation?: Location }) ?? {}

  const connections = useConnectionSecrets({
    connectionNames: ['account', 'tosopr004'],
  })
  const assets = useMemo(
    () => ({
      robotenIconPng: '/assets/roboten-icon-png/roboten-icon.png',
      mobilePhonesPng: '/assets/mobile-phones-png/mobile-phones.png',
      githubSvg: '/assets/github-svg/Github.svg',
      googleSvg: '/assets/google-svg/Google.svg',
      linkedinSvg: '/assets/linkedin-svg/Linkedin.svg',
      twitterSvg: '/assets/twitter-svg/Twitter.svg',
      myAccountEditProfileSvg:
        '/assets/my-account-edit-profile-svg/My account_Edit profile.svg',
      adminCloseAccountSvg:
        '/assets/admin-close-account-svg/Admin_Close account.svg',
      adminEditGroupDialogSvg:
        '/assets/admin-edit-group-dialog-svg/Admin_Edit group dialog.svg',
      adminCreateGroupDialogSvg:
        '/assets/admin-create-group-dialog-svg/Admin_Create group dialog.svg',
      myAccountChangePasswordSvg:
        '/assets/my-account-change-password-svg/My account_Change password.svg',
      adminInviteUserSvg: '/assets/admin-invite-user-svg/Admin_Invite user.svg',
      myAccountLanguageAndRegionSvg:
        '/assets/my-account-language-and-region-svg/My account_Language and region.svg',
      closeAccountCloseAccountSvg:
        '/assets/close-account-close-account-svg/close_account-close_account.svg',
      myAccountCreateEmailAndPasswordLoginDialogSvg:
        '/assets/my-account-create-email-and-password-login-dialog-svg/My account_Create email and password login dialog.svg',
      myAccountEmailAndPasswordSvg:
        '/assets/my-account-email-and-password-svg/My account_Email and password.svg',
      myAccountDeleteEmailSvg:
        '/assets/my-account-delete-email-svg/My account_Delete Email.svg',
      myAccountDeletePhoneNumberSvg:
        '/assets/my-account-delete-phone-number-svg/My account_Delete phone number.svg',
      myAccountAddEmailSvg:
        '/assets/my-account-add-email-svg/My account_Add email.svg',
      faviconPng: '/assets/favicon-png/favicon.png',
      myAccountEmailAndPassword_2xPng:
        '/assets/my-account-email-and-password-2x-png/My account_Email and password@2x.png',
      driveSvg: '/assets/drive-svg/drive.svg',
      appIcon_01Svg: '/assets/app-icon-01-svg/app_icon_01.svg',
      appIcon_02Svg: '/assets/app-icon-02-svg/app_icon_02.svg',
      appIcon_03Svg: '/assets/app-icon-03-svg/app_icon_03.svg',
      appIcon_05Svg: '/assets/app-icon-05-svg/app_icon_05.svg',
      appIcon_04Svg: '/assets/app-icon-04-svg/app_icon_04.svg',
      appIcon_06Svg: '/assets/app-icon-06-svg/app_icon_06.svg',
      appIcon_07Svg: '/assets/app-icon-07-svg/app_icon_07.svg',
      appIcon_09Svg: '/assets/app-icon-09-svg/app_icon_09.svg',
      appIcon_08Svg: '/assets/app-icon-08-svg/app_icon_08.svg',
      notFoundSvg: '/assets/not-found-svg/not-found.svg',
      firefoxPng: '/assets/firefox-png/Firefox.png',
      operaPng: '/assets/opera-png/Opera.png',
      chromePng: '/assets/chrome-png/Chrome.png',
      webBrowserJpg: '/assets/web-browser-jpg/WebBrowser.jpg',
      safariPng: '/assets/safari-png/Safari.png',
    }),
    []
  )
  return (
    <>
      <StateProvider
        additionalState={{
          assets,
          connections,
        }}
        store={store}
      >
        <RobotenDnDProvider>
          <DialogProvider>
            <>
              <React.Suspense fallback={null}>
                <Routes location={state?.backgroundLocation ?? location}>
                  <Route
                    path=""
                    element={
                      <ViewWrapper
                        namespace={'db06db6d-6a27-4f36-b59e-3a69a32c6126'}
                      >
                        <MasterView />
                      </ViewWrapper>
                    }
                  >
                    <Route
                      path="/"
                      element={
                        <ViewWrapper
                          namespace={'06899d5d-36ff-4755-8c31-2393b233ae44'}
                        >
                          <MasterProfileView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/emails/:id"
                      element={
                        <ViewWrapper
                          namespace={'0acdc3e4-2fc0-4fdd-8abb-045847bd34b1'}
                        >
                          <MasterEmailsView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/api-keys"
                      element={
                        <ViewWrapper
                          namespace={'5f974461-01de-42d8-86bd-e51bbed8b3e6'}
                        >
                          <MasterApiKeysView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/api-keys/api-key-created"
                      element={
                        <ViewWrapper
                          namespace={'94a6d16e-acdd-4096-936f-5d73bd3317aa'}
                        >
                          <MasterApiKeysApiKeyCreatedView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/api-keys/create-api-key"
                      element={
                        <ViewWrapper
                          namespace={'990591b3-8c8b-47b1-9229-bddaf7a836d2'}
                        >
                          <MasterApiKeysCreateApiKeyView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/mobile-phones/:id"
                      element={
                        <ViewWrapper
                          namespace={'365ec608-fe12-4e0e-bd23-600c6357b523'}
                        >
                          <MasterMobilePhonesView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/login-methods"
                      element={
                        <ViewWrapper
                          namespace={'d20d0ef9-6d31-4e2b-b5e7-acca609b137b'}
                        >
                          <MasterLoginMethodsView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/close-account/:id"
                      element={
                        <ViewWrapper
                          namespace={'dcf3e9a2-e22c-4700-af79-eb53faa1b390'}
                        >
                          <MasterCloseAccountView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/security"
                      element={
                        <ViewWrapper
                          namespace={'333501d2-67e3-4b98-908e-15b91970aa52'}
                        >
                          <MasterSecurityView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path=""
                      element={
                        <ViewWrapper
                          namespace={'c73ca553-a266-44b8-9c95-48e6edbb198c'}
                        >
                          <MasterMenusView />
                        </ViewWrapper>
                      }
                    >
                      <Route
                        path="/mobile-phones-menu/:id"
                        element={
                          <ViewWrapper
                            namespace={'476a55bc-ac11-468f-9753-3dd621a056bc'}
                          >
                            <MasterMenusMobilePhonesMenuView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/emails-menu/:id"
                        element={
                          <ViewWrapper
                            namespace={'20680f8e-ba9e-4c1e-b8b7-1a33ae35bd71'}
                          >
                            <MasterMenusEmailsMenuView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/api-keys-menu"
                        element={
                          <ViewWrapper
                            namespace={'7b32ef38-0945-4902-bff4-a9850e524844'}
                          >
                            <MasterMenusApiKeysMenuView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/user-menu"
                        element={
                          <ViewWrapper
                            namespace={'19bf860a-699e-48ce-8077-e7ac902e5455'}
                          >
                            <MasterMenusUserMenuView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/application-menu"
                        element={
                          <ViewWrapper
                            namespace={'defd4990-c12d-4b48-b60e-9eb7291fdfb2'}
                          >
                            <MasterMenusApplicationMenuView />
                          </ViewWrapper>
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path="/*"
                    element={
                      <ViewWrapper
                        namespace={'6e95aeed-4ae4-454b-846e-a963cdce69b4'}
                      >
                        <NotFoundView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path=""
                    element={
                      <ViewWrapper
                        namespace={'41acc6fb-de6e-4670-ac27-07dd900712dc'}
                      >
                        <ModalsView />
                      </ViewWrapper>
                    }
                  >
                    <Route
                      path="/add-mobile-phone/:id"
                      element={
                        <ViewWrapper
                          namespace={'a1f490b5-6a6b-4c42-9512-8fa1ef5a0c56'}
                        >
                          <ModalsAddMobilePhoneView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/add-email/:id"
                      element={
                        <ViewWrapper
                          namespace={'8e566cf8-7aa3-4dd1-8f3c-dadf4fee7914'}
                        >
                          <ModalsAddEmailView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/add-email-and-password/:id"
                      element={
                        <ViewWrapper
                          namespace={'71185db8-81d8-4aab-a580-cdac6455ab68'}
                        >
                          <ModalsAddEmailAndPasswordView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/confirm-delete-email/:id"
                      element={
                        <ViewWrapper
                          namespace={'10aae4f0-b150-48b9-8329-be18dbe03ce6'}
                        >
                          <ModalsConfirmDeleteEmailView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/edit-profile/:id"
                      element={
                        <ViewWrapper
                          namespace={'de72153c-56e8-4aa7-9dff-fa44b93f1e31'}
                        >
                          <ModalsEditProfileView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/language-and-region/:id"
                      element={
                        <ViewWrapper
                          namespace={'d98e477f-4d36-4a90-a128-76deee704ebe'}
                        >
                          <ModalsLanguageAndRegionView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/confirm-delete-mobile-phone/:id"
                      element={
                        <ViewWrapper
                          namespace={'d35094d0-5fd7-4a85-9547-d421c6a80c54'}
                        >
                          <ModalsConfirmDeleteMobilePhoneView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/confirm-close-account/:id"
                      element={
                        <ViewWrapper
                          namespace={'2dd974de-0cf9-44a4-9700-4b78f86d7ad1'}
                        >
                          <ModalsConfirmCloseAccountView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/avatar-editor"
                      element={
                        <ViewWrapper
                          namespace={'a00e390b-9ac3-48d9-bd20-39d751b19d34'}
                        >
                          <ModalsAvatarPickerView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/change-password/:id"
                      element={
                        <ViewWrapper
                          namespace={'81960b67-6549-4a69-a91b-7d7b91750e6d'}
                        >
                          <ModalsChangePasswordView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/confirm-remove-login/:id"
                      element={
                        <ViewWrapper
                          namespace={'5c2f4e55-f322-44dd-a293-1eb9a782c7e6'}
                        >
                          <ModalsConfirmRemoveLoginView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/add-social-login"
                      element={
                        <ViewWrapper
                          namespace={'7db53b3a-f37a-4259-b95b-2169bad5d6ef'}
                        >
                          <ModalsAddSocialLoginView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/confirm-delete-identity-assignment/:id"
                      element={
                        <ViewWrapper
                          namespace={'935ba7b7-a29f-48ac-8616-34b7e3565580'}
                        >
                          <ModalsConfirmDeleteIdentityAssignmentView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/confirm-delete-log-entry/:id"
                      element={
                        <ViewWrapper
                          namespace={'04d210e7-57b2-433c-abf9-3c22270e305f'}
                        >
                          <ModalsConfirmDeleteLoginLogEntryView />
                        </ViewWrapper>
                      }
                    />
                  </Route>

                  <Route
                    path="/auth/account/callback"
                    element={<AccountAuthCallback />}
                  />

                  <Route
                    path="/auth/tosopr004/callback"
                    element={<Tosopr004AuthCallback />}
                  />
                </Routes>

                {/* Show the modal when a `backgroundLocation` is set */}
                {state?.backgroundLocation && (
                  <Routes>
                    <Route
                      path=""
                      element={
                        <ModalUnstyled
                          open={!!state?.backgroundLocation}
                          style={modalStyles}
                        >
                          <div>
                            <Outlet />
                          </div>
                        </ModalUnstyled>
                      }
                    >
                      <Route
                        path=""
                        element={
                          <ViewWrapper
                            namespace={'db06db6d-6a27-4f36-b59e-3a69a32c6126'}
                          >
                            <MasterView />
                          </ViewWrapper>
                        }
                      >
                        <Route
                          path="/"
                          element={
                            <ViewWrapper
                              namespace={'06899d5d-36ff-4755-8c31-2393b233ae44'}
                            >
                              <MasterProfileView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/emails/:id"
                          element={
                            <ViewWrapper
                              namespace={'0acdc3e4-2fc0-4fdd-8abb-045847bd34b1'}
                            >
                              <MasterEmailsView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/api-keys"
                          element={
                            <ViewWrapper
                              namespace={'5f974461-01de-42d8-86bd-e51bbed8b3e6'}
                            >
                              <MasterApiKeysView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/api-keys/api-key-created"
                          element={
                            <ViewWrapper
                              namespace={'94a6d16e-acdd-4096-936f-5d73bd3317aa'}
                            >
                              <MasterApiKeysApiKeyCreatedView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/api-keys/create-api-key"
                          element={
                            <ViewWrapper
                              namespace={'990591b3-8c8b-47b1-9229-bddaf7a836d2'}
                            >
                              <MasterApiKeysCreateApiKeyView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/mobile-phones/:id"
                          element={
                            <ViewWrapper
                              namespace={'365ec608-fe12-4e0e-bd23-600c6357b523'}
                            >
                              <MasterMobilePhonesView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/login-methods"
                          element={
                            <ViewWrapper
                              namespace={'d20d0ef9-6d31-4e2b-b5e7-acca609b137b'}
                            >
                              <MasterLoginMethodsView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/close-account/:id"
                          element={
                            <ViewWrapper
                              namespace={'dcf3e9a2-e22c-4700-af79-eb53faa1b390'}
                            >
                              <MasterCloseAccountView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/security"
                          element={
                            <ViewWrapper
                              namespace={'333501d2-67e3-4b98-908e-15b91970aa52'}
                            >
                              <MasterSecurityView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path=""
                          element={
                            <ViewWrapper
                              namespace={'c73ca553-a266-44b8-9c95-48e6edbb198c'}
                            >
                              <MasterMenusView />
                            </ViewWrapper>
                          }
                        >
                          <Route
                            path="/mobile-phones-menu/:id"
                            element={
                              <ViewWrapper
                                namespace={
                                  '476a55bc-ac11-468f-9753-3dd621a056bc'
                                }
                              >
                                <MasterMenusMobilePhonesMenuView />
                              </ViewWrapper>
                            }
                          />
                          <Route
                            path="/emails-menu/:id"
                            element={
                              <ViewWrapper
                                namespace={
                                  '20680f8e-ba9e-4c1e-b8b7-1a33ae35bd71'
                                }
                              >
                                <MasterMenusEmailsMenuView />
                              </ViewWrapper>
                            }
                          />
                          <Route
                            path="/api-keys-menu"
                            element={
                              <ViewWrapper
                                namespace={
                                  '7b32ef38-0945-4902-bff4-a9850e524844'
                                }
                              >
                                <MasterMenusApiKeysMenuView />
                              </ViewWrapper>
                            }
                          />
                          <Route
                            path="/user-menu"
                            element={
                              <ViewWrapper
                                namespace={
                                  '19bf860a-699e-48ce-8077-e7ac902e5455'
                                }
                              >
                                <MasterMenusUserMenuView />
                              </ViewWrapper>
                            }
                          />
                          <Route
                            path="/application-menu"
                            element={
                              <ViewWrapper
                                namespace={
                                  'defd4990-c12d-4b48-b60e-9eb7291fdfb2'
                                }
                              >
                                <MasterMenusApplicationMenuView />
                              </ViewWrapper>
                            }
                          />
                        </Route>
                      </Route>
                      <Route
                        path="/*"
                        element={
                          <ViewWrapper
                            namespace={'6e95aeed-4ae4-454b-846e-a963cdce69b4'}
                          >
                            <NotFoundView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path=""
                        element={
                          <ViewWrapper
                            namespace={'41acc6fb-de6e-4670-ac27-07dd900712dc'}
                          >
                            <ModalsView />
                          </ViewWrapper>
                        }
                      >
                        <Route
                          path="/add-mobile-phone/:id"
                          element={
                            <ViewWrapper
                              namespace={'a1f490b5-6a6b-4c42-9512-8fa1ef5a0c56'}
                            >
                              <ModalsAddMobilePhoneView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/add-email/:id"
                          element={
                            <ViewWrapper
                              namespace={'8e566cf8-7aa3-4dd1-8f3c-dadf4fee7914'}
                            >
                              <ModalsAddEmailView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/add-email-and-password/:id"
                          element={
                            <ViewWrapper
                              namespace={'71185db8-81d8-4aab-a580-cdac6455ab68'}
                            >
                              <ModalsAddEmailAndPasswordView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/confirm-delete-email/:id"
                          element={
                            <ViewWrapper
                              namespace={'10aae4f0-b150-48b9-8329-be18dbe03ce6'}
                            >
                              <ModalsConfirmDeleteEmailView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/edit-profile/:id"
                          element={
                            <ViewWrapper
                              namespace={'de72153c-56e8-4aa7-9dff-fa44b93f1e31'}
                            >
                              <ModalsEditProfileView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/language-and-region/:id"
                          element={
                            <ViewWrapper
                              namespace={'d98e477f-4d36-4a90-a128-76deee704ebe'}
                            >
                              <ModalsLanguageAndRegionView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/confirm-delete-mobile-phone/:id"
                          element={
                            <ViewWrapper
                              namespace={'d35094d0-5fd7-4a85-9547-d421c6a80c54'}
                            >
                              <ModalsConfirmDeleteMobilePhoneView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/confirm-close-account/:id"
                          element={
                            <ViewWrapper
                              namespace={'2dd974de-0cf9-44a4-9700-4b78f86d7ad1'}
                            >
                              <ModalsConfirmCloseAccountView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/avatar-editor"
                          element={
                            <ViewWrapper
                              namespace={'a00e390b-9ac3-48d9-bd20-39d751b19d34'}
                            >
                              <ModalsAvatarPickerView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/change-password/:id"
                          element={
                            <ViewWrapper
                              namespace={'81960b67-6549-4a69-a91b-7d7b91750e6d'}
                            >
                              <ModalsChangePasswordView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/confirm-remove-login/:id"
                          element={
                            <ViewWrapper
                              namespace={'5c2f4e55-f322-44dd-a293-1eb9a782c7e6'}
                            >
                              <ModalsConfirmRemoveLoginView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/add-social-login"
                          element={
                            <ViewWrapper
                              namespace={'7db53b3a-f37a-4259-b95b-2169bad5d6ef'}
                            >
                              <ModalsAddSocialLoginView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/confirm-delete-identity-assignment/:id"
                          element={
                            <ViewWrapper
                              namespace={'935ba7b7-a29f-48ac-8616-34b7e3565580'}
                            >
                              <ModalsConfirmDeleteIdentityAssignmentView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/confirm-delete-log-entry/:id"
                          element={
                            <ViewWrapper
                              namespace={'04d210e7-57b2-433c-abf9-3c22270e305f'}
                            >
                              <ModalsConfirmDeleteLoginLogEntryView />
                            </ViewWrapper>
                          }
                        />
                      </Route>
                    </Route>
                  </Routes>
                )}
              </React.Suspense>
            </>
          </DialogProvider>
        </RobotenDnDProvider>
      </StateProvider>
    </>
  )
}
export default App
